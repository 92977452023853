import React from 'react';
import classes from './OriginalDeals.module.scss';
import Plus from '../../components/Reusable/SVG/Plus';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import DealsAvtiveL from '../../components/Reusable/SVG/DealsAvtiveL';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import { Radio } from '@mui/joy';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import { Checkbox, Fab } from '@mui/material';
import Button from '../../components/Reusable/UI/Button/Button';
import { useState } from 'react';
import Section from '../../components/Reusable/Common/Section/Section';
import Edit from '../../components/Reusable/SVG/Edit';
import ManagerCard from '../../components/Reusable/Common/ManagerCard/ManagerCard';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import Img from '../../components/Reusable/Common/Img';
import Close from '../../components/Reusable/SVG/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { useEffect } from 'react';
import { productService } from '../../services/ProductServices';
import { useNavigate } from 'react-router-dom';
import AddSection from '../../components/Reusable/Common/AddMemberCard/AddSection';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { suppliersHelper } from '../../Helper/Suppliers/Supplier';
import { originalDealsEshtreanaService } from '../../services/OriginalDealsEshtreana';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';

export default function OriginalDealForm() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [addSupplierSectionForm, setAddSupplierSectionForm] = useState(false);
  const [addCustomerSectionForm, setAddCustomerSectionForm] = useState(false);
  const [addDeliverySectionForm, setAddDeliverySectionForm] = useState(false);
  const [dropDownLoading, setDropDownLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [option, setOption] = useState('Main Data');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading1, setLoading1] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsNames, setProductsNames] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [singleQuantityLoading, setSingleQuantityLoading] = useState(false);
  const [cities, setCitites] = useState([1]);
  const [areas, setAreas] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedDisrictFinal, setSelectedDistrict] = useState([[]]);
  const [changed, setChanged] = useState(false);
  const [supplierTerms, setSupplierTerms] = useState([]);
  const [customerTerms, setCustomerTerms] = useState([]);
  const [deliveryTerms, setDeliveyryTerms] = useState([]);
  const [showEditFormSupplier, setShowEditFormSupplier] = useState(false);
  const [showEditFormCustomer, setShowEditFormCustomer] = useState(false);
  const [showEditFormDelivery, setShowEditFormDelivery] = useState(false);
  const [quantityValid, setQuantityValid] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [singleQuantity, setSingleQuantity] = useState(null);
  const [sectionData, setSectionData] = useState({
    title_en: null,
    title_ar: null,
    content_en: null,
    content_ar: null,
  });
  const [dealData, setDealData] = useState({
    customerType: 'Both',
    bigDeal: true,
    allKSA: true,
    quantities: [],
    unit: null,
    deposit: null,
    deliveryResponsibility: 'Eshtarena',
    deliveryFees: null,
  });
  const [dealDataErr, setDealDataErr] = useState({
    customerType: true,
    bigDeal: true,
    allKSA: true,
    deposit: false,
    quantities: false,
    unit: false,
    deliveryResponsibility: true,
    deliveryFees: false,
    supplierTerms: true,
    customerTerms: true,
    deliveryTerms: true,
  });

  let handleDeleteQuantity = (quantity) => {
    let arr = [];
    dealData.quantities.filter((item) => item !== quantity && arr.push(item));
    setDealData((prev) => ({ ...prev, quantities: [...arr] }));
    setDealDataErr((prev) => ({
      ...prev,
      quantities: arr.length > 0 ? false : true,
    }));
  };

  let handleChange = (e) => {
    setDealData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setDealDataErr((prev) => ({
      ...prev,
      [e.target.name]: e.target.value !== '' ? true : false,
    }));

    if (e.target.name === 'expireDate') {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000) setDealDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
    if (e.target.name === 'deposit' || e.target.name === 'deliveryFees') {
      var isANumber = isNaN(e.target.value) === false;

      isANumber ? setDealDataErr((prev) => ({ ...prev, [e.target.name]: true })) : setDealDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      (parseFloat(e.target.value) < 0 ||
        e.target.value.startsWith('-') ||
        e.target.value === '' ||
        // e.target.value === 0 ||
        e.target.value.startsWith('+')) &&
        setDealDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
  };
  let handelAddQuantity = (e) => {
    e.preventDefault();
    let isNumber = true;
    if (!Number(singleQuantity)) {
      isNumber = false;
      setQuantityValid(false);
    }
    singleQuantity === '' && setQuantityValid(true);

    if (singleQuantity && isNumber) {
      setQuantityValid(true);
      if (!dealData.quantities.includes(singleQuantity)) {
        setDealData((prev) => ({
          ...prev,
          quantities: [...dealData.quantities, singleQuantity],
        }));
        setDealDataErr((prev) => ({ ...prev, quantities: true }));
      }
    }
    setTimeout(() => {
      setSingleQuantityLoading(false);
    }, 1000);
  };
  let handelAddQuantity1 = () => {
    let isNumber = true;
    if (!Number(singleQuantity)) {
      isNumber = false;
      setQuantityValid(false);
    }
    singleQuantity === '' && setQuantityValid(true);

    if (singleQuantity && isNumber) {
      setQuantityValid(true);
      if (!dealData.quantities.includes(singleQuantity)) {
        setDealData((prev) => ({
          ...prev,
          quantities: [...dealData.quantities, singleQuantity],
        }));
        setDealDataErr((prev) => ({ ...prev, quantities: true }));
      }
    }
    setTimeout(() => {
      setSingleQuantityLoading(false);
    }, 1000);
  };

  // TODO:Helper
  let getProducts = async () => {
    let response = await productService.getProducts(page, 10);
    //console.log(response);
    setTotalPages(response.data.totalPages);

    let arr = [];
    [...products, ...response.data.products].map((product) => {
      arr.push(product.name_en);
    });

    setProducts((prev) => [...products, ...response.data.products]);
    setLoading1(false);

    setProductsNames(arr);
  };
  useEffect(() => {
    setDropDownLoading(true);
    getProducts();
  }, [page]);
  let getSingleProduct = async (id) => {
    let response = await productService.getSingleProduct(id);

    setSelectedProduct(response.data.product);
    setLoadingProduct(false);
  };

  let handleSubmit = async () => {
    // !dealData.bigDeal && setDealDataErr((prev) => ({ ...prev, deposit: true }));
    if (dealData.deliveryResponsibility !== 'Eshtarena') setDealDataErr((prev) => ({ ...prev, deliveryFees: true }));
    let finalArea = [];
    let finalSupplierTerms = [];
    let finalCustomerTerms = [];
    let finalDeliveryTerms = [];
    let finalSuppliers = [];
    let temp = {
      city: '',
      district: [],
    };
    let ind = -1;
    if (!dealData.workingAreas) {
      areas.map((item, index) => {
        if (selectedCities.includes(item.city_en)) {
          ind++;
          temp.city = item._id;
          finalArea[ind] = [];
          item.district &&
            item.district.map((district, i) => {
              if (selectedDisrictFinal[ind]) {
                if (selectedDisrictFinal[ind][0] === 'all') {
                  temp.district.push(district._id);
                } else if (selectedDisrictFinal[ind].includes(district.district_en)) {
                  temp.district.push(district._id);
                }
              }
            });
          finalArea[ind] = temp;
          temp = {
            city: '',
            district: [],
          };
        }
      });
    }

    selectedSuppliers.map((item) => finalSuppliers.push(item._id));
    selectedSuppliers.map((item) => finalSuppliers.push(item._id));
    supplierTerms.map((item) => {
      if (item.selected) {
        finalSupplierTerms.push({
          title_en: item.title_en,
          title_ar: item.title_ar,
          desc_en: item.content_en,
          desc_ar: item.content_ar,
        });
      }
    });

    customerTerms.map((item) => {
      if (item.selected) {
        finalCustomerTerms.push({
          title_en: item.title_en,
          title_ar: item.title_ar,
          desc_en: item.content_en,
          desc_ar: item.content_ar,
        });
      }
    });
    deliveryTerms.map((item) => {
      if (item.selected) {
        finalDeliveryTerms.push({
          title_en: item.title_en,
          title_ar: item.title_ar,
          desc_en: item.content_en,
          desc_ar: item.content_ar,
        });
      }
    });

    finalSupplierTerms.length === 0 && setDealDataErr((prev) => ({ ...prev, supplierTerms: false }));
    finalCustomerTerms.length === 0 && setDealDataErr((prev) => ({ ...prev, customerTerms: false }));
    dealData.deliveryResponsibility === 'Eshtarena' &&
      finalDeliveryTerms.length === 0 &&
      setDealDataErr((prev) => ({ ...prev, deliveryTerms: false }));
    setShowError(true);
    if (
      dealDataErr.quantities &&
      dealDataErr.unit &&
      ((dealData.bigDeal && dealDataErr.deposit) || !dealData.bigDeal) &&
      selectedProduct &&
      selectedProduct._id &&
      finalCustomerTerms.length !== 0 &&
      finalSupplierTerms.length !== 0 &&
      ((dealData.deliveryResponsibility === 'Eshtarena' && finalDeliveryTerms.length !== 0 && dealDataErr.deliveryFees) ||
        dealData.deliveryResponsibility !== 'Eshtarena') &&
      selectedSuppliers.length !== 0
    ) {
      let quantities = [];
      dealData.quantities.map((item) => quantities.push({ quantity: parseInt(item) }));

      let data1 = {
        customerType: dealData.customerType,
        isBigDeal: dealData.bigDeal,
        allKsa: dealData.allKSA,
        product: selectedProduct._id,
        quantities: quantities,
        unit: dealData.unit,
        deliveryResponsibility: dealData.deliveryResponsibility,
        supplierPaymentTerms: finalSupplierTerms,
        customerPaymentTerms: finalCustomerTerms,
        suppliers: finalSuppliers,
      };
      if (dealData.bigDeal) data1.deposit = parseInt(dealData.deposit);

      if (!dealData.allKSA) data1.cities = finalArea;

      if (dealData.deliveryResponsibility === 'Eshtarena') {
        data1.deliveryTerms = finalDeliveryTerms;
        data1.deliveryFees = dealData.deliveryFees;
      }
      let response = await originalDealsEshtreanaService.createDealRequest({
        ...data1,
      });
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          history(`/account/original-deal`, { replace: true });
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem('errorMsg'),
          }),
        );
      }
    } else {
      let arr = [];
      Object.keys(dealDataErr).forEach(function eachKey(key) {
        if (dealDataErr[key] === false)
          if (key === 'deposit') {
            if (dealData.bigDeal) arr.push(key);
          } else arr.push(key);
      });
      !selectedProduct && arr.push('Product');
      dealData.workingAreas && selectedCities.length === 0 && arr.push('Cities');
      finalCustomerTerms.length === 0 && arr.push('Customer Payment Terms');
      dealData.deliveryResponsibility === 'Eshtarena' && finalDeliveryTerms.length === 0 && arr.push('Delivery Terms');
      finalSupplierTerms.length === 0 && arr.push('Supplier Payment Terms');
      finalSuppliers.length === 0 && arr.push('Suppliers');

      setServerErrorMsg(`There a Missing Data are Required [${[...arr]}]`);
      setServerError(true);
    }

    setLoadingButton(false);
  };
  let handleAddSection = (data) => {
    if (option === 'Supplier Payment Terms') {
      if (sectionData.title_en) {
        let arr = [];
        supplierTerms.map((item1, index1) => {
          if (selectedIndex === index1) {
            arr.push({
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: sectionData.selected,
            });
          } else
            arr.push({
              title_en: item1.title_en,
              title_ar: item1.title_ar,
              content_en: item1.content_en,
              content_ar: item1.content_ar,
              selected: item1.selected,
            });
        });
        setSupplierTerms(arr);
      } else {
        setSupplierTerms((prev) => [
          ...prev,
          {
            title_en: data.titleEN,
            title_ar: data.titleAR,
            content_en: data.descriptionEN,
            content_ar: data.descriptionAR,
            selected: true,
          },
        ]);
      }
    } else {
      if (option === 'Delivery') {
        if (sectionData.title_en) {
          let arr = [];
          deliveryTerms.length > 0 &&
            deliveryTerms.map((item1, index1) => {
              if (selectedIndex === index1) {
                arr.push({
                  title_en: data.titleEN,
                  title_ar: data.titleAR,
                  content_en: data.descriptionEN,
                  content_ar: data.descriptionAR,
                  selected: sectionData.selected,
                });
              } else
                arr.push({
                  title_en: item1.title_en,
                  title_ar: item1.title_ar,
                  content_en: item1.content_en,
                  content_ar: item1.content_ar,
                  selected: item1.selected,
                });
            });
          setDeliveyryTerms(arr);
        } else {
          setDeliveyryTerms((prev) => [
            ...prev,
            {
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: true,
            },
          ]);
        }
      } else {
        if (sectionData.title_en) {
          let arr = [];
          customerTerms.map((item1, index1) => {
            if (selectedIndex === index1) {
              arr.push({
                title_en: data.titleEN,
                title_ar: data.titleAR,
                content_en: data.descriptionEN,
                content_ar: data.descriptionAR,
                selected: sectionData.selected,
              });
            } else
              arr.push({
                title_en: item1.title_en,
                title_ar: item1.title_ar,
                content_en: item1.content_en,
                content_ar: item1.content_ar,
                selected: item1.selected,
              });
          });
          setCustomerTerms(arr);
        } else {
          setCustomerTerms((prev) => [
            ...prev,
            {
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: true,
            },
          ]);
        }
      }
    }
  };

  useEffect(() => {
    suppliersHelper.getSuppliers(setServerError, setServerErrorMsg, setSuppliers, selectedSuppliers, searchValue, setLoading);
  }, [searchValue, loading]);

  useEffect(() => {
    let arr = [];
    selectedCities.map((item) => {
      areas.map((item1) => item1.city_en === item && arr.push(item1.district));
    });
    setDistrict(arr);
  }, [changed]);

  useEffect(() => {
    getProducts();
    mainDataHelper.getCities(setServerError, setServerErrorMsg, setAreas);
    mainDataHelper.getSupplierTerms(setServerError, setServerErrorMsg, setSupplierTerms);
    mainDataHelper.getCustomerTerms(setServerError, setServerErrorMsg, setCustomerTerms);
    mainDataHelper.getDeliveryTerms(setServerError, setServerErrorMsg, setDeliveyryTerms);
  }, []);

  useEffect(() => {
    setSelectedProduct(null);
    products.map((item) => {
      if (item.name_en === selectedProductName) {
        getSingleProduct(item._id);
        return;
      }
    });
  }, [selectedProductName]);

  useEffect(() => {
    if (serverError) {
      setTimeout(() => {
        setServerError(false);
      }, 4000);
    }
  }, [serverError]);

  return (
    <div className={`w-100 ${classes['original-deals']}`}>
      {(addSupplierSectionForm ||
        addCustomerSectionForm ||
        addDeliverySectionForm ||
        showEditFormCustomer ||
        showEditFormDelivery ||
        showEditFormSupplier) && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            addSupplierSectionForm && setAddSupplierSectionForm(false);
            addDeliverySectionForm && setAddDeliverySectionForm(false);
            addCustomerSectionForm && setAddCustomerSectionForm(false);
            showEditFormCustomer && setShowEditFormCustomer(false);
            showEditFormSupplier && setShowEditFormSupplier(false);
            showEditFormDelivery && setShowEditFormDelivery(false);
          }}
        >
          <AddSection
            data={(showEditFormCustomer || showEditFormSupplier || showEditFormDelivery) && sectionData}
            handleSubmit={(data) => handleAddSection(data)}
            add={addCustomerSectionForm || addSupplierSectionForm || addDeliverySectionForm}
            edit={showEditFormCustomer || showEditFormSupplier || showEditFormDelivery}
            setShowForm={() => {
              addDeliverySectionForm && setAddDeliverySectionForm(false);

              addSupplierSectionForm && setAddSupplierSectionForm(false);
              addCustomerSectionForm && setAddCustomerSectionForm(false);
              showEditFormCustomer && setShowEditFormCustomer(false);
              showEditFormDelivery && setShowEditFormDelivery(false);
              showEditFormSupplier && setShowEditFormSupplier(false);
            }}
          />
        </Modal>
      )}

      {showConfirmation && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowConfirmation(false);
          }}
        >
          <SuccessPopup message1={'The Deal Request is Created Successfully'} />
        </Modal>
      )}

      {serverError && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      <CompanyNav />
      <section className="custom-container">
        <PageTitle text={'Deal preparation Request'} icon={<DealsAvtiveL />} />
        <section className={`${classes['main-section']}`}>
          <div className={`row px-0 mx-0   ${classes.options}`}>
            <SelectOption
              onSelect={(val) => setOption(val)}
              default={option}
              options={['Main Data', 'Delivery', 'Supplier Payment Terms', 'Customer Payment Terms', 'Suppliers']}
            />
          </div>
          {option === 'Main Data' && (
            <>
              <div className="row px-0 mx-0">
                <div className="col-lg-6 px-0 pe-lg-2">
                  <div className={classes.input}>
                    <p>Customer Type</p>
                    <div className={`row p-0 m-0 ${classes['input--options']}`}>
                      {['B2C', 'B2B', 'Both'].map((item, index) => (
                        <div key={index} className="w-auto d-inline d-flex align-items-center ">
                          <Radio
                            checked={dealData.customerType === item ? true : false}
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                customerType: item,
                              }));
                            }}
                            className="h-auto px-0 py-0"
                          />
                          <label
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                customerType: item,
                              }));
                            }}
                            className="h-auto "
                          >
                            &nbsp; {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-0 ps-lg-2">
                  <div className={classes.input}>
                    <p>Big Deal</p>
                    <div className={`row p-0 m-0 ${classes['input--options']}`}>
                      {['Yes', 'No'].map((item, index) => (
                        <div key={index} className="w-auto d-inline d-flex align-items-center ">
                          <Radio
                            checked={(dealData.bigDeal && item === 'Yes') || (!dealData.bigDeal && item === 'No') ? true : false}
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                bigDeal: item === 'Yes' ? true : false,
                              }));
                            }}
                            className="h-auto px-0 py-0"
                          />
                          <label
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                bigDeal: item === 'Yes' ? true : false,
                              }));
                            }}
                            className="h-auto "
                          >
                            &nbsp; {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-12 px-0 pe-lg-2">
                  <div className={classes.input}>
                    <p>Working Areas</p>
                    <div className={`row p-0 m-0 ${classes['input--options']}`}>
                      {['All KSA', 'Different Areas'].map((item, index) => (
                        <div key={index} className="w-auto d-inline d-flex align-items-center ">
                          <Radio
                            checked={(dealData.allKSA && item === 'All KSA') || (!dealData.allKSA && item === 'Different Areas') ? true : false}
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                allKSA: item === 'All KSA' ? true : false,
                              }));
                            }}
                            className="h-auto px-0 py-0"
                          />
                          <label
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                allKSA: item === 'All KSA' ? true : false,
                              }));
                            }}
                            className="h-auto "
                          >
                            &nbsp; {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {!dealData.allKSA &&
                (areas.length > 0 ? (
                  cities.map((item, index) => (
                    <div key={index} className="row px-0 mx-0">
                      <div className="col-lg-6 px-0 pe-lg-2">
                        <div className={classes.input}>
                          <InputGroup
                            type="dropdown"
                            city={true}
                            default={selectedCities[index] ? selectedCities[index] : 'select City'}
                            options={['select City', ...areas]}
                            error={showError && selectedCities.length === 0}
                            errorMsg="Provide a City"
                            onChange={(e) => {
                              let arr = selectedCities;
                              let arr2 = [];
                              if (selectedCities.includes(e.target.value) || e.target.value === 'select City') {
                                arr.map((item, ind) => {
                                  if (index !== ind) {
                                    arr2.push(item);
                                  }
                                });
                                setSelectedCities(arr2);
                                let arr1 = selectedDisrictFinal;
                                arr1[index] = [];
                                setSelectedDistrict(arr1);
                              } else {
                                arr[index] = e.target.value;
                                setSelectedCities((prev) => arr);
                                let arr1 = selectedDisrictFinal;
                                arr1[index] = [];
                                setSelectedDistrict(arr1);
                              }
                              setChanged((prev) => !prev);
                            }}
                            value={'Cities'}
                          />
                          {cities.length === index + 1 && (
                            <p
                              onClick={() => {
                                setSelectedCities((prev) => [...prev, []]);

                                setCitites((prev) => [...prev, cities.length]);
                              }}
                              className={`${classes['input--link']}`}
                            >
                              <Plus color="#949494" /> Add City
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 px-0 ps-lg-2">
                        <div className={classes.input}>
                          <InputGroup
                            type="dropdown"
                            default={'select District'}
                            options={districts[index] && ['select District', ...districts[index]]}
                            district={true}
                            error={showError && selectedCities.length === 0}
                            errorMsg="Provide a District"
                            selected={selectedDisrictFinal[index]}
                            onChange={(e) => {
                              if (e.target.value !== 'select District' && !selectedDisrictFinal[index].includes(e.target.value)) {
                                let arr = selectedDisrictFinal;
                                arr[index] = arr[index] ? [...arr[index], e.target.value] : [e.target.value];
                                setSelectedDistrict(arr);
                                setChanged((prev) => !prev);
                              }
                            }}
                            value={'Districts'}
                          />
                          <p className={`${classes['input--options']}`}>
                            <Checkbox
                              checked={
                                selectedDisrictFinal[index] && selectedDisrictFinal[index][0] && selectedDisrictFinal[index][0] === 'all'
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let arr = selectedDisrictFinal;
                                arr[index] = ['all'];
                                setSelectedDistrict(arr);
                                setChanged((prev) => !prev);
                              }}
                            />
                            All districts
                          </p>
                        </div>
                      </div>
                      <div className="row px-0 mx-0 mb-3 ">
                        {selectedDisrictFinal[index] &&
                          selectedDisrictFinal[index].length > 0 &&
                          selectedDisrictFinal[index].map((item, ind) => (
                            <div key={ind} className="w-auto d-inline mt-2 pe-lg-2 position-relative">
                              <div
                                onClick={() => {
                                  let arr = [];

                                  if (selectedDisrictFinal[index][0] === 'all') {
                                    let arr = selectedDisrictFinal;
                                    arr[index] = [];
                                    setSelectedDistrict(arr);
                                    setChanged((prev) => !prev);
                                  } else {
                                    arr = [];
                                    arr = selectedDisrictFinal[index].filter((item1, index) => {
                                      if (item1 !== item) return item1;
                                    });
                                    if (arr.length > 0) {
                                      selectedDisrictFinal[index] = arr;
                                      setSelectedDistrict(selectedDisrictFinal);
                                    } else {
                                      selectedDisrictFinal[index] = [];
                                      setSelectedDistrict(selectedDisrictFinal);
                                    }
                                    setChanged((prev) => !prev);
                                  }
                                }}
                                style={{
                                  right: '10px',
                                  zIndex: 2,
                                  // top: "10px",
                                }}
                                className="text-end  cursor  h-100 align-items-center d-flex position-absolute"
                              >
                                <Close />
                              </div>
                              <Fab style={{ zIndex: 1 }} variant="extended" className=" pe-5">
                                {item}
                              </Fab>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-100 row justify-content-center pt-5 pb-4">
                    <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Citites Added Yet</div>
                  </div>
                ))}

              {dealData.bigDeal && (
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        error={showError && !dealDataErr.deposit}
                        errorMsg={dealData.deposit ? 'Provide a Valid Deal Deposit' : 'Provide a Deal Deposit'}
                        name="deposit"
                        onChange={(e) => handleChange(e)}
                        value={'Deposit'}
                        default={dealData.deposit}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="row px-0 mx-0">
                <div className="col-12 px-0 ">
                  <div className={classes.input}>
                    <InputGroup
                      type="dropdown"
                      autoComplete={true}
                      options={['selecte Product', ...productsNames]}
                      default={selectedProductName ? selectedProductName : 'select Product'}
                      linkText={{
                        text: '+ Add Product',
                        fn: () => {
                          window.scrollTo({
                            top: 0,
                            left: 100,
                            behavior: 'smooth',
                          });
                          history('/account/product/new', { replace: true });
                        },
                      }}
                      onChange={(e) => {
                        if (e.target.value === 'loadMore') {
                          setLoading1(true);
                          setPage((prev) => prev + 1);
                        } else {
                          setLoadingProduct(true);
                          setSelectedProductName(e.target.value);
                        }
                      }}
                      error={showError && !selectedProductName}
                      pagination={loading1 || totalPages <= page ? false : true}
                      errorMsg="Provide a Product"
                      value={'Choose Product'}
                      loading={loading1}
                    />
                  </div>
                </div>
              </div>
              {
                <>
                  {loadingProduct
                    ? null
                    : selectedProduct && (
                        <div className="row px-0 mx-0">
                          <div className="col-xl-9 order-xl-1 order-2 px-0">
                            <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                              <div className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p>Product Name :</p>
                                  <span>{selectedProduct.name_en}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p>Model :</p>
                                  <span>{selectedProduct.model}</span>
                                </div>
                              </div>
                            </div>
                            <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                              <div dir="ltr" className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p dir="ltr">اسم المنتج :</p>
                                  <span dir="ltr">{selectedProduct.name_ar}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p>Country of Origin :</p>
                                  <span>{selectedProduct.country_en}</span>
                                </div>
                              </div>
                            </div>
                            <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                              <div className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p>Product Code :</p>
                                  <span>{selectedProduct.uuid}</span>
                                </div>
                              </div>
                              <div className="col-lg-6  col-12 px-0">
                                <div dir="ltr" className={classes['single-item']}>
                                  <p dir="ltr" className="order-1">
                                    بلد المنشأ :
                                  </p>
                                  <span dir="ltr" className="order-2">
                                    {selectedProduct.country_ar}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                              <div className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p>Category :</p>
                                  <span>{selectedProduct.category.name_en}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p>Factory Name :</p>
                                  <span>{selectedProduct.factory_en}</span>
                                </div>
                              </div>
                            </div>
                            <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                              <div className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p>Sub Category :</p>
                                  <span>{selectedProduct.subcategory.name_en}</span>
                                </div>
                              </div>
                              <div dir="ltr" className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p dir="ltr">اسم المصنع :</p>
                                  <span dir="ltr">{selectedProduct.factory_ar}</span>
                                </div>
                              </div>
                            </div>
                            <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                              <div className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p>Market Price :</p>
                                  <span>{selectedProduct.price}</span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 px-0">
                                <div className={classes['single-item']}>
                                  <p>Batch Number :</p>
                                  <span>{selectedProduct.patchNumber}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3  order-xl-2 order-1 mb-lg-0 mb-2 px-0 p-xl-0 ">
                            <div className={`${classes.info}  h-100 align-items-center justify-content-center d-flex`}>
                              <Img
                                style={{
                                  objectFit: 'cover',
                                }}
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/product/${selectedProduct.defaultImg}`}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                </>
              }
              {selectedProduct && (
                <>
                  <div className="row px-0 mx-0">
                    <div className="col-12 px-0 ">
                      <div className={classes.input}>
                        <p>Description</p>
                      </div>
                    </div>
                  </div>
                  <div className="row px-0 mx-0">
                    <div className="col-xl-6 pe-xl-2 px-0">
                      <Section border={true} text={selectedProduct.description_en} />
                    </div>

                    <div className="col-xl-6 ps-xl-2 px-0  ms-auto">
                      <Section border={true} text={selectedProduct.description_ar} />
                    </div>
                  </div>
                </>
              )}
              <div className="row px-0 mx-0">
                {singleQuantityLoading ? (
                  <div className="row px-0 mx-0 align-items-end">
                    <div className="col-9 px-0 ">
                      <div className={classes.input}>
                        <InputGroup
                          default={''}
                          onChange={(e) => {
                            setSingleQuantity(e.target.value);
                          }}
                          name="quantities"
                          value={'Quantities'}
                        />
                      </div>
                    </div>
                    <div className="ps-3 col-3 pb-3 d-inline text-center px-0">
                      <div className="text-center">
                        <span className="spinner-border spinner-border-sm"></span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <form
                    className="px-0  mx-0"
                    onSubmit={(e) => {
                      setSingleQuantityLoading(true);
                      handelAddQuantity(e);
                    }}
                  >
                    <div
                      className={`row px-0 mx-0 ${
                        (showError && !dealDataErr.quantities) || (!quantityValid && singleQuantity !== null)
                          ? ' align-items-center'
                          : ' align-items-end'
                      }`}
                    >
                      <div className="col-9 px-0  ">
                        <div className={classes.input}>
                          <InputGroup
                            default={null}
                            name="Quantity"
                            onChange={(e) => setSingleQuantity(e.target.value)}
                            value={'Quantities'}
                            error={(showError && !dealDataErr.quantities) || (!quantityValid && singleQuantity !== null) ? true : false}
                            errorMsg={!quantityValid ? 'The Quantity is Not Valid' : showError && 'Provide a Deal Quantites'}
                          />
                        </div>
                      </div>
                      <div className="ps-3 col-3 pb-3 d-inline px-0">
                        <Button
                          onClick={() => {
                            setSingleQuantityLoading(true);
                            handelAddQuantity1(singleQuantity);
                          }}
                          icon={<Plus />}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          className={'col-12 d-inline text-center  ms-auto '}
                          type="normal"
                          text={'Add'}
                        />
                      </div>
                    </div>
                  </form>
                )}

                <div className="row">
                  {dealData.quantities.map((item, index) => (
                    <span key={index} className={`w-auto d-inline ${classes['quantities-item']}`}>
                      {item}
                      <div
                        onClick={() => {
                          handleDeleteQuantity(item);
                        }}
                        className="w-auto d-inline"
                        style={{ paddingInlineStart: '8px' }}
                      >
                        <Close color="#134074" />
                      </div>
                    </span>
                  ))}
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-6 px-0 pe-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={dealData.unit}
                      name="unit"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dealDataErr.unit}
                      errorMsg="Provide an Unit"
                      value={'Unit'}
                    />
                  </div>
                </div>
              </div>
              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                <div className="w-lg-auto  ms-auto d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Delivery');
                      }}
                      // icon={<Plus />}
                      color={'#D1E6FF'}
                      borderColor={'#134074'}
                      fontColor={'#134074'}
                      className={'w-lg-auto  d-inline px-5  ms-auto '}
                      type="normal"
                      text={'Next'}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {option === 'Delivery' && (
            <>
              <div className="row px-0 mx-0">
                <div className="col-12 px-0 pe-2">
                  <div className={classes.input}>
                    <p>Delivery responsibility</p>
                    <div className={`row p-0 m-0 ${classes['input--options']}`}>
                      {['Eshtarena', 'Supplier', 'Pick From supplier location'].map((item, index) => (
                        <div key={index} className="w-auto d-inline d-flex align-items-center ">
                          <Radio
                            checked={item === dealData.deliveryResponsibility}
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                deliveryResponsibility: item,
                              }));
                            }}
                            className="h-auto px-0 py-0"
                          />
                          <label
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                deliveryResponsibility: item,
                              }));
                            }}
                            className="h-auto cursor "
                          >
                            &nbsp; {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {dealData.deliveryResponsibility === 'Eshtarena' && (
                <>
                  <div className="row  pt-0 px-0 mx-0">
                    <div className="col-12 px-0 pe-2 ">
                      <div className={`${classes.input1}   row d-flex align-items-center`}>
                        <p className="w-auto d-inline">Delivery Terms & Conditions</p>

                        <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                          <div className={`  mb-pb-0`}>
                            <Button
                              onClick={() => {
                                setDealDataErr((prev) => ({
                                  ...prev,
                                  deliveryTerms: true,
                                }));

                                setSectionData({
                                  title_en: null,
                                  title_ar: null,
                                  content_en: null,
                                  content_ar: null,
                                });
                                setAddDeliverySectionForm(true);
                                // setOption("Communication Data");
                              }}
                              color={'#D1E6FF'}
                              borderColor={'#134074'}
                              fontColor={'#134074'}
                              icon={<Plus />}
                              className={'w-auto px-2 ms-auto text-end'}
                              type="normal"
                              text={'Add Section'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showError && !dealDataErr.deliveryTerms && (
                    <div className="text-center">
                      <span className="errorMsg text-cenetr">Provide Delivery Terms & Conditions.</span>
                    </div>
                  )}

                  {deliveryTerms.length > 0 ? (
                    deliveryTerms.map((item, index) => (
                      <div key={index} className="row mt-0 px-0 mx-0">
                        <div className="col-lg-5 col-10 px-0">
                          <Section
                            checkBox={true}
                            onClick={() => {
                              setDealDataErr((prev) => ({
                                ...prev,
                                deliveryTerms: true,
                              }));

                              let arr = [];
                              deliveryTerms.map((item1, index1) => {
                                if (index === index1) {
                                  arr.push({
                                    title_en: item1.title_en,
                                    title_ar: item1.title_ar,
                                    content_en: item1.content_en,
                                    content_ar: item1.content_ar,
                                    selected: !item1.selected,
                                  });
                                } else
                                  arr.push({
                                    title_en: item1.title_en,
                                    title_ar: item1.title_ar,
                                    content_en: item1.content_en,
                                    content_ar: item1.content_ar,
                                    selected: item1.selected,
                                  });
                              });
                              setDeliveyryTerms(arr);
                              // setChanged(!changed);
                            }}
                            checked={item.selected}
                            title={item.title_en}
                            text={item.content_en}
                          />
                        </div>
                        <div className="col-lg-2 col-2 justify-content-center">
                          <div className="row px-0 mx-0 mt-4 pt-2">
                            <div
                              onClick={() => {
                                setSectionData({ ...item });
                                setSelectedIndex(index);
                                setShowEditFormDelivery(true);
                              }}
                              className="w-auto mx-auto"
                            >
                              <Edit />
                            </div>
                          </div>
                        </div>
                        <div className="d-lg-none d-block col-1"></div>
                        <div
                          // dir="rtl"
                          className="col-lg-5 col-10  px-0  ms-lg-auto me-auto"
                        >
                          <Section title={item.title_ar} text={item.content_ar} />
                        </div>
                        {/* <div className="col-6 px-0"></div> */}
                      </div>
                    ))
                  ) : (
                    <div className="w-100 row justify-content-center pt-5 pb-4">
                      <div
                        // style={{ color: "#134074" }}
                        className="no-found-data mx-auto w-auto  text-center w-100 "
                      >
                        There are No Terms & Condition Added yet.
                      </div>
                    </div>
                  )}
                  <div className="row px-0 mx-0">
                    <div className="col-6 px-0 ">
                      <div className={classes.input}>
                        <InputGroup
                          linkText={{ text: 'SAR', fn: () => {} }}
                          name="deliveryFees"
                          default={dealData.deliveryFees}
                          onChange={(e) => handleChange(e)}
                          error={showError && !dealDataErr.deliveryFees}
                          errorMsg={dealData.deliveryFees ? 'provide a Valid Delivery fees' : 'provide a Delivery fees'}
                          value={'Delivery Fees'}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {dealData.deliveryResponsibility === 'Supplier' && (
                <div className="w-100 row justify-content-center pt-5 pb-4">
                  <div style={{ color: '#134074' }} className="no-found-data mx-auto w-auto  text-center w-100 ">
                    Suppliers will Add his Terms & Condition.
                  </div>
                </div>
              )}
              {dealData.deliveryResponsibility === 'Pick From supplier location' && (
                <div className="w-100 row justify-content-center pt-5 pb-4">
                  <div style={{ color: '#134074' }} className="no-found-data mx-auto w-auto  text-center w-100 ">
                    Supplier will Specify the Pick Locations.
                  </div>
                </div>
              )}

              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                {/* <div className="w-auto  ms-auto"> */}
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Main Data');
                      }}
                      // icon={<Plus />}

                      className={'w-lg-auto  d-inline px-5   '}
                      type="reverse"
                      text={'Back'}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Supplier Payment Terms');
                      }}
                      // icon={<Plus />}
                      color={'#D1E6FF'}
                      borderColor={'#134074'}
                      fontColor={'#134074'}
                      className={'w-lg-auto  d-inline px-5  ms-auto '}
                      type="normal"
                      text={'Next'}
                    />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </>
          )}
          {option === 'Supplier Payment Terms' && (
            <>
              <div className="row px-0 mx-0">
                <div className="col-12 px-0 pe-2">
                  <div className={`${classes.input} row d-flex align-items-center`}>
                    <p className="w-auto d-inline">Payment Terms & Conditions</p>

                    <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            setDealDataErr((prev) => ({
                              ...prev,
                              supplierTerms: true,
                            }));

                            setSectionData({
                              title_en: null,
                              title_ar: null,
                              content_en: null,
                              content_ar: null,
                            });
                            setAddSupplierSectionForm(true);
                            // setOption("Communication Data");
                          }}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          icon={<Plus />}
                          className={'w-auto px-2 ms-auto text-end'}
                          type="normal"
                          text={'Add Section'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {supplierTerms.length > 0 ? (
                supplierTerms.map((item, index) => (
                  <div key={index} className="row mt-0 px-0 mx-0">
                    <div className="col-lg-5 col-10 px-0">
                      <Section
                        checkBox={true}
                        onClick={() => {
                          setDealDataErr((prev) => ({
                            ...prev,
                            supplierTerms: true,
                          }));
                          let arr = [];
                          supplierTerms.map((item1, index1) => {
                            if (index === index1) {
                              arr.push({
                                title_en: item1.title_en,
                                title_ar: item1.title_ar,
                                content_en: item1.content_en,
                                content_ar: item1.content_ar,
                                selected: !item1.selected,
                              });
                            } else
                              arr.push({
                                title_en: item1.title_en,
                                title_ar: item1.title_ar,
                                content_en: item1.content_en,
                                content_ar: item1.content_ar,
                                selected: item1.selected,
                              });
                          });
                          setSupplierTerms(arr);
                          // setChanged(!changed);
                        }}
                        checked={item.selected}
                        title={item.title_en}
                        text={item.content_en}
                      />
                    </div>
                    <div className="col-lg-2 col-2 justify-content-center">
                      <div className="row px-0 mx-0 mt-4 pt-2">
                        <div
                          onClick={() => {
                            setSectionData({ ...item });
                            setSelectedIndex(index);
                            setShowEditFormSupplier(true);
                          }}
                          className="w-auto mx-auto"
                        >
                          <Edit />
                        </div>
                      </div>
                    </div>
                    <div className="d-lg-none d-block col-1"></div>
                    <div
                      // dir="rtl"
                      className="col-lg-5 col-10  px-0  ms-lg-auto me-auto"
                    >
                      <Section title={item.title_ar} text={item.content_ar} />
                    </div>
                    {/* <div className="col-6 px-0"></div> */}
                  </div>
                ))
              ) : (
                <div className=" row justify-content-center px-0  py-5 pb-4">
                  <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Terms Added Yet.</div>
                </div>
              )}

              {showError && !dealDataErr.supplierTerms && (
                <div className="text-center mb-3 row px-0 ">
                  <span className="errorMsg text-cenetr w-100 ">Provide Supplier Terms & Conditions.</span>
                </div>
              )}

              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                {/* <div className="w-auto  ms-auto"> */}
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Delivery');
                      }}
                      // icon={<Plus />}

                      className={'w-lg-auto  d-inline px-5   '}
                      type="reverse"
                      text={'Back'}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Customer Payment Terms');
                      }}
                      // icon={<Plus />}
                      color={'#D1E6FF'}
                      borderColor={'#134074'}
                      fontColor={'#134074'}
                      className={'w-lg-auto  d-inline px-5  ms-auto '}
                      type="normal"
                      text={'Next'}
                    />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </>
          )}
          {option === 'Customer Payment Terms' && (
            <>
              <div className="row px-0 mx-0">
                <div className="col-12 px-0 pe-2">
                  <div className={`${classes.input} row d-flex align-items-center`}>
                    <p className="w-auto d-inline">Payment Terms & Conditions</p>

                    <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            setDealDataErr((prev) => ({
                              ...prev,
                              customerTerms: true,
                            }));

                            setSectionData({
                              title_en: null,
                              title_ar: null,
                              content_en: null,
                              content_ar: null,
                            });
                            setAddCustomerSectionForm(true);
                            // setOption("Communication Data");
                          }}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          icon={<Plus />}
                          className={'w-auto px-2 ms-auto text-end'}
                          type="normal"
                          text={'Add Section'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {customerTerms.length > 0 ? (
                customerTerms.map((item, index) => (
                  <div key={index} className="row mt-0 px-0 mx-0">
                    <div className="col-lg-5 col-10 px-0">
                      <Section
                        checkBox={true}
                        onClick={() => {
                          setDealDataErr((prev) => ({
                            ...prev,
                            customerTerms: true,
                          }));

                          let arr = [];
                          customerTerms.map((item1, index1) => {
                            if (index === index1) {
                              arr.push({
                                title_en: item1.title_en,
                                title_ar: item1.title_ar,
                                content_en: item1.content_en,
                                content_ar: item1.content_ar,
                                selected: !item1.selected,
                              });
                            } else
                              arr.push({
                                title_en: item1.title_en,
                                title_ar: item1.title_ar,
                                content_en: item1.content_en,
                                content_ar: item1.content_ar,
                                selected: item1.selected,
                              });
                          });
                          setCustomerTerms(arr);
                          // setChanged(!changed);
                        }}
                        checked={item.selected}
                        title={item.title_en}
                        text={item.content_en}
                      />
                    </div>
                    <div className="col-lg-2 col-2 justify-content-center">
                      <div className="row px-0 mx-0 mt-4 pt-2">
                        <div
                          onClick={() => {
                            setSectionData({ ...item });
                            setSelectedIndex(index);
                            setShowEditFormCustomer(true);
                          }}
                          className="w-auto mx-auto"
                        >
                          <Edit />
                        </div>
                      </div>
                    </div>
                    <div className="d-lg-none d-block col-1"></div>
                    <div
                      // dir="rtl"
                      className="col-lg-5 col-10  px-0  ms-lg-auto me-auto"
                    >
                      <Section title={item.title_ar} text={item.content_ar} />
                    </div>
                    {/* <div className="col-6 px-0"></div> */}
                  </div>
                ))
              ) : (
                <div className=" row justify-content-center px-0  py-5 pb-4">
                  <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Terms Added Yet.</div>
                </div>
              )}

              {showError && !dealDataErr.customerTerms && (
                <div className="text-center mb-3 row px-0 ">
                  <span className="errorMsg text-cenetr w-100 ">Provide Customer Terms & Conditions.</span>
                </div>
              )}
              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                {/* <div className="w-auto  ms-auto"> */}
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Supplier Payment Terms');
                      }}
                      // icon={<Plus />}

                      className={'w-lg-auto  d-inline px-5   '}
                      type="reverse"
                      text={'Back'}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Suppliers');
                      }}
                      // icon={<Plus />}
                      color={'#D1E6FF'}
                      borderColor={'#134074'}
                      fontColor={'#134074'}
                      className={'w-lg-auto  d-inline px-5  ms-auto '}
                      type="normal"
                      text={'Next'}
                    />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </>
          )}

          {option === 'Suppliers' && (
            <>
              {selectedSuppliers.length > 0 && (
                <div div className="row px-0 mx-0 ">
                  <Swiper
                    // slidesPerView={5}
                    // width={props.type === 'Product' && 500}
                    spaceBetween={10}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                      1025: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                      1441: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                    }}
                    loop={false}
                    loopFillGroupWithBlank={true}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper    align-items-center d-flex p-0 "
                  >
                    {selectedSuppliers.length > 0 &&
                      selectedSuppliers.map((item, index) => (
                        <SwiperSlide key={index} className="col-xl-3 col-lg-6">
                          <ManagerCard
                            delete={true}
                            profilePicture={item.pic}
                            type="supplier"
                            onRemove={() => {
                              let arr = [];
                              selectedSuppliers.map((i) => {
                                if (i._id !== item._id) {
                                  arr.push(i);
                                }
                              });
                              setSelectedSuppliers(arr);
                              setLoading(true);
                            }}
                            options={[
                              {
                                title: 'Supplier Name',
                                value: item.name_en,
                              },
                              {
                                title: 'Category',
                                value: item.categories,
                                list: true,
                              },
                            ]}
                          />
                          {/* </div> */}
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              )}

              {showError && selectedSuppliers.length === 0 && (
                <div className="text-center mb-3">
                  <span className="errorMsg text-cenetr">Provide Suppliers you Want to send the Deal to them .</span>
                </div>
              )}

              <div className="row px-0 mx-0 pt-4">
                <div className="col-12 px-0 ">
                  <div className={classes.input}>
                    <p>Choose Suppliers</p>
                    <SearchInput
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      placeholder="Search for Suppliers"
                    />

                    <div className={classes['supplier-result']}>
                      {suppliers.length > 0 ? (
                        suppliers.map((item, index) => (
                          <div key={index} className={`row px-0 mx-0 d-flex align-items-center ${classes['supplier-option']}`}>
                            <div className="w-auto d-inline">
                              <Img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${item.pic}`} />
                            </div>
                            <div className="w-auto d-inline">
                              <div className={`row px-0 mx-0 ${classes['supplier-name']}`}>{item.name_en}</div>
                              <div className={`row px-0 mx-0 ${classes['supplier-category']}`}>
                                {item.categories.map((item1, index1) => (
                                  <span key={index1} className="w-auto px-0 d-inline pe-lg-2">
                                    {item1}
                                  </span>
                                ))}
                              </div>
                            </div>
                            {/* <div className="row mx-0 px-0  mb-pb-0"> */}

                            {selectedSuppliers[0]?._id !== item._id && (
                              <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                                <div className={`  mb-pb-0`}>
                                  <Button
                                    onClick={() => {
                                      // setOption("Communication Data");
                                      setSelectedSuppliers((prev) => [...prev, item]);
                                      setLoading(true);
                                      // getSuppliers();
                                      // setChanged(!changed);
                                    }}
                                    // icon={<Plus />}
                                    color={'#D1E6FF'}
                                    borderColor={'#134074'}
                                    fontColor={'#134074'}
                                    className={'w-auto px-2  ms-auto text-end'}
                                    type="normal"
                                    text={'Choose Supplier'}
                                  />
                                </div>
                              </div>
                            )}

                            {/* </div> */}
                          </div>
                        ))
                      ) : (
                        <div className="w-100 row justify-content-center pt-5 pb-4">
                          <div
                            // style={{ color: "#134074" }}
                            className="no-found-data mx-auto w-auto  text-center w-100 "
                          >
                            There are No Matched Suppliers
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                {/* <div className="w-auto  ms-auto"> */}
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Main Data');
                      }}
                      // icon={<Plus />}

                      className={'w-lg-auto  d-inline px-5   '}
                      type="reverse"
                      text={'Back'}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    {loadingButton ? (
                      <div className="text-center">
                        <span className="spinner-border spinner-border-sm"></span>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setLoadingButton(true);
                          handleSubmit();
                        }}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        className={'w-lg-auto  d-inline px-5  ms-auto '}
                        type="normal"
                        text={'Send Request'}
                      />
                    )}
                  </div>
                </div>
                {/* </div> */}
              </div>
            </>
          )}
        </section>
      </section>
    </div>
  );
}
