import './App.css';
import SideBar from './components/Reusable/Common/SideBar/SideBar';

import PolicyPage from './Pages/Policies/PolicyPage';
import Category from './Pages/Category/Category';
import ProductForm from './Pages/Product/ProductForm';
import Products from './Pages/Product/Products';
import { Route, Routes, useNavigate } from 'react-router-dom';
import About from './Pages/About/About';
import ProductDetails from './Pages/Product/ProductDetails';
import SuppliersForm from './Pages/Suppliers/SuppliersForm';
import Suppliers from './Pages/Suppliers/Suppliers';
import SuppierDetails from './Pages/Suppliers/SuppierDetails';
import SupplierDataDetails from './Pages/Suppliers/SupplierDataDetails';
import ErrorPage from './Pages/ErrorPages/ErrorPage';
import Managers from './Pages/Managers/Managers';
import ManagerDetails from './Pages/Managers/ManagerDetails';
import ManagerDetailsData from './Pages/Managers/ManagerDetailsData';
import AdandAdviseForm from './Pages/Ads&Advise/AdandAdviseForm';
import Login from './Pages/Login/Login';
import OriginalDealForm from './Pages/OriginalDeals/OriginalDealForm';
import Deals from './Pages/OriginalDeals/Deals';
import DealsDetails from './Pages/OriginalDeals/DealsDetails';
import Voucher from './Pages/Voucher/Voucher';
import SupplierVoucher from './Pages/Voucher1/Voucher';
import VoucherDetails from './Pages/Voucher/VoucherDetails';
import VoucherForm from './Pages/Voucher/VoucherForm';
import VoucherResponseDetails from './Pages/Voucher/VoucherResponseDetails';
import SupplierVoucherDetails from './Pages/Voucher1/VoucherDetails';
import VoucherRequestDetails from './Pages/Voucher1/VoucherRequestDetails';
import ScanedVoucher from './Pages/Voucher1/ScanedVoucher';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Area from './Pages/Area/Area';
import Requests from './Pages/Requests/Requests';
import Ads from './Pages/Managers/Ads';
import Advice from './Pages/Managers/Advice';
import Cashiers from './Pages/Cashier/Cashiers';
import CashierDetails from './Pages/Cashier/CashierDetails';
import CashierForm from './Pages/Cashier/CashierForm';
import CashierMainPage from './Pages/Cashier/CashierMainPage';

import CashierResult from './Pages/Cashier/CashierResult';
import BarcodeScanner from './Pages/Cashier/BarCodeScanner';
import DealResponseDetails from './Pages/OriginalDeals/DealResponseDetails';
import SupplierDeals from './Pages/OriginalDeals1/Deals';
import SupplierDealsDetails from './Pages/OriginalDeals1/DealsDetails';
import ColdDealsFrom from './Pages/ColdDeals/ColdDealsForm';
import ColdDeals from './Pages/ColdDeals/ColdDeals';
import ColdDealsDetails from './Pages/ColdDeals/ColdDealsDetails';
import SupplierColdDeals from './Pages/ColdDeals1/ColdDeals1';
import SupplierColdDealsDetails from './Pages/ColdDeals1/ColdDealsDdetails';
import ProductRequest from './Pages/Requests/ContactRequest';
import { errorActions } from './store/reducers/errorSlice';
import { scanActions } from './store/reducers/scanSlice';
import Login1 from './Pages/Login/Login1';
import PoliciesPage from './Pages/PoliciesPage/PoliciesPage';
import Landing from './Pages/Landing/Landing';
import Section1 from './components/Sections/Section1';
import Landing1 from './Pages/Landing/Landing1';
import MainData from './Pages/MainData/MainData';
import DealShare from './Pages/DealShare/DealShare';
function App() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { scan } = useSelector((state) => state.scan);
  const { error, errorMsg, counter } = useSelector((state) => state.error);

  useEffect(() => {
    dispatch(errorActions.setError({ error: false, errorMsg: '' }));
    dispatch(scanActions.setScan({ scan: scan }));
  }, []);

  useEffect(() => {
    if (window.location.pathname !== '/scanner') {
      if (scan === true) {
        dispatch(scanActions.setScan({ scan: false }));
        localStorage.setItem('scan', 'false');
        window.location.reload();
      }
    }
  }, [history]);

  useEffect(() => {
    //console.log(error);
    if (error) {
      if (errorMsg && errorMsg.toLowerCase().includes('unauthorized')) {
        history(`/unauthorized`, { replace: true });
        if (localStorage.getItem('side') === 'Top Manager') history(`/account/product`);
        else if (localStorage.getItem('side') === 'Marketing Manager') history(`/account/ad`);
        else if (localStorage.getItem('side') === 'Category Manager') history(`/account/product`);
        else if (localStorage.getItem('side') === 'Supplier') history(`/account/suppliers/${localStorage.getItem('id')}`);
        else if (localStorage.getItem('side') === 'Cashier') history(`/cashier`);
      } else if (errorMsg.toLowerCase().includes('offline')) {
        history(`/offline`);
      } else {
        // history('/intrnel-server-error', { replace: true });
      }
    }
  }, [counter]);

  useEffect(() => {}, []);
  return (
    <>
      {!localStorage.getItem('token') ? (
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/share-deal/:id" element={<DealShare />} />
          <Route path="/login" element={<Login />} />
          <Route path="/v1/deal/cold/:id" element={<Landing1 />} />
          <Route path="/v1/deal/details/:id" element={<Landing1 />} />
          <Route path="/v1/deal/original/:id" element={<Landing1 />} />

          <Route path="*" element={<Login />} />
          <Route path="/terms-conditions" element={<PoliciesPage type={'Terms & Conditions'} />} />
          <Route path="/privacy-policy" element={<PoliciesPage type={'Privacy Policy'} />} />
        </Routes>
      ) : (
        <div className="row mx-0 px-0 d-sm-flex d-none row-cols-2   w-100  ">
          {!(
            window.location.pathname.startsWith('/v1/deal/cold') ||
            window.location.pathname.startsWith('/v1/deal/details') ||
            window.location.pathname.startsWith('/v1/deal/original')
          ) && (
            <div id="side-menu" className="col w-auto d-sm-inline  d-none px-0">
              <SideBar />
            </div>
          )}
          {(window.location.pathname === '/scanner' || (window.location.pathname !== '/scanner' && !scan)) && (
            <div className={`  px-0  col  d-flex flex-grow-1`}>
              <Routes>
                <Route path="/v1/deal/cold/:id" element={<Landing1 />} />
                <Route path="/v1/deal/details/:id" element={<Landing1 />} />
                <Route path="/v1/deal/original/:id" element={<Landing1 />} />

                <Route path="/terms-conditions" element={<PoliciesPage login={true} type={'Terms & Conditions'} />} />
                <Route path="/privacy-policy" element={<PoliciesPage login={true} type={'Privacy Policy'} />} />
                <Route path="/terms-conditions" element={<PoliciesPage type={'Terms & Conditions'} />} />
                <Route path="/privacy-policy" element={<PoliciesPage type={'Privacy Policy'} />} />
                <Route path="/login" element={<Login1 />} />
                <Route
                  path="/unauthorized"
                  element={
                    <ErrorPage
                      type="401"
                      title="Error 401, Unauthorized Access!"
                      text={`Uh-oh! Looks like you're trying to access a restricted area without the proper credentials.`}
                    />
                  }
                />
                <Route
                  path="/intrnel-server-error"
                  element={<ErrorPage type="500" title="Something Wrong Happens!" text={`We're Working Now on Fixing it.`} />}
                />
                <Route path="/offline" element={<ErrorPage type="offline" title="You're Offline" text={`Reconnect your Internet.`} />} />
                <Route path="/account/product" element={<Products />} />
                <Route path="/account/area-serve" element={<Area />} />
                <Route path="/account/product/new" element={<ProductForm />} />
                <Route path="/account/clients-requests" element={<Requests />} />
                <Route path="/account/product-requests" element={<ProductRequest />} />
                <Route path="/account/product/:id/edit" element={<ProductForm />} />
                <Route path="/account/product/:id" element={<ProductDetails />} />
                <Route path="/account/marketing-manager" element={<Managers type="Marketing Managers" />} />

                <Route path="/account/marketing-manager/new-ad" element={<AdandAdviseForm type="Ad" />} />
                <Route path="/account/ad" element={<Ads />} />
                <Route path="/account/advice" element={<Advice />} />
                <Route
                  path="/account/ad/new-ad"
                  element={<AdandAdviseForm type="Ad" />}
                  // element={<Ads />}
                />
                <Route
                  path="/account/advice/new-advice"
                  element={<AdandAdviseForm type="Ad" />}
                  // element={<Advice />}
                />
                <Route
                  path="/supplier/cashiers"
                  element={<Cashiers />}
                  // element={<Advice />}
                />
                <Route
                  path="/supplier/cashiers/:id"
                  element={<CashierDetails />}
                  // element={<Advice />}
                />
                <Route
                  path="/supplier/cashiers/new"
                  element={<CashierForm />}
                  // element={<Advice />}
                />
                <Route
                  path="/account/cashier/:id"
                  element={<CashierDetails />}
                  // element={<Advice />}
                />
                <Route
                  path="/supplier/cashiers/:id/edit"
                  element={<CashierForm />}
                  // element={<Advice />}
                />
                <Route
                  path="/cashier"
                  element={<CashierMainPage />}
                  // element={<Advice />}
                />
                <Route
                  path="/cashier/:id"
                  element={<CashierResult />}
                  // element={<Advice />}
                />
                <Route
                  path="/scanner"
                  element={<BarcodeScanner />}
                  // element={<Advice />}
                />
                <Route path="/account/marketing-manager/new-advice" element={<AdandAdviseForm type="Advice" />} />

                <Route path="/account/marketing-manager/:id" element={<ManagerDetails type="Marketing Manager" />} />
                <Route path="/account/marketing-manager/:id/edit" element={<ManagerDetailsData type="Marketing Manager" />} />
                <Route path="/account/category-manager/:id/edit" element={<ManagerDetailsData type="Category Manager" />} />
                <Route path="/account/suppliers" element={<Suppliers />} />
                <Route path="/account/suppliers/new" element={<SuppliersForm />} />
                <Route path="/account/suppliers/:id" element={<SuppierDetails />} />
                <Route path="/account/suppliers/:id/data" element={<SupplierDataDetails />} />
                <Route path="/account/suppliers/:id/data/edit" element={<SuppliersForm />} />
                <Route path="/account/privacy-policy" element={<PolicyPage type="Privacy Policy" />} />
                <Route path="/account/terms-conditions" element={<PolicyPage type="Terms & Conditions" />} />
                <Route path="/account/delivery-terms" element={<PolicyPage type="Delivery Terms" />} />
                <Route path="/account/payment-policy" element={<PolicyPage type="Payment Policy" />} />
                <Route path="/account/category" element={<Category type="Category" />} />
                <Route path="/account/sub-category" element={<Category type="Sub Category" />} />
                <Route path="/account/about" element={<About />} />
                <Route path="/account/category-manager" element={<Managers type="Category Managers" />} />
                <Route path="/account/category-manager/:id" element={<ManagerDetails type="Category Manager" />} />
                <Route path="/account/marketing-manager/advice/:id/edit" element={<AdandAdviseForm type="Advice" />} />
                <Route path="/account/marketing-manager/ad/:id/edit" element={<AdandAdviseForm type="Ad" />} />
                <Route path="/account/advice/advice/:id/edit" element={<AdandAdviseForm type="Advice" />} />
                <Route path="/account/ad/:id/edit" element={<AdandAdviseForm type="Ad" />} />
                <Route path="/account/original-deal/new" element={<OriginalDealForm />} />
                <Route path="/account/cold-deal/new" element={<ColdDealsFrom />} />
                <Route path="/account/cold-deal/:type/:id" element={<ColdDealsDetails />} />
                <Route path="/account/cold-deal" element={<ColdDeals />} />
                <Route path="/account/main-data" element={<MainData />} />

                <Route path="/supplier/cold-deal/:type/:id" element={<SupplierColdDealsDetails />} />
                <Route path="/supplier/cold-deal" element={<SupplierColdDeals />} />
                {/* <Route
                path="/account/cold-deal/:id"
                element={<ColdDealsDetails />}
              /> */}
                <Route path="/account/original-deal" element={<Deals />} />
                <Route path="/Supplier/original-deal" element={<SupplierDeals />} />
                <Route path="/supplier/original-deal/:type/:id" element={<SupplierDealsDetails />} />

                <Route path="/account/original-deal/offer/:id" element={<DealResponseDetails />} />
                <Route path="/account/original-deal/:type/:id" element={<DealsDetails />} />

                <Route path="/account/Voucher" element={<Voucher />} />
                <Route path="/account/Voucher/on-going/:id" element={<VoucherDetails type="On Going" />} />
                <Route path="/account/Voucher/scheduled/:id" element={<VoucherDetails type="Scheduled" />} />
                <Route path="/account/Voucher/history/:id" element={<VoucherDetails type="History" />} />
                <Route path="/account/Voucher/response/:id" element={<VoucherResponseDetails />} />
                <Route path="/supplier/Voucher" element={<SupplierVoucher />} />
                <Route path="/account/Voucher/new" element={<VoucherForm />} />
                <Route path="/supplier/Voucher/on-going/:id" element={<SupplierVoucherDetails tyoe="On Going" />} />
                <Route path="/supplier/Voucher/history/:id" element={<SupplierVoucherDetails type="In Use" />} />
                <Route path="/supplier/Voucher/scheduled/:id" element={<SupplierVoucherDetails type="Scheduled" />} />
                <Route path="/supplier/Voucher/requests/:id" element={<VoucherRequestDetails />} />
                <Route path="/supplier/Voucher/scan/:id" element={<ScanedVoucher />} />

                <Route path="/" element={<Login1 />} />

                {
                  <Route
                    path="*"
                    element={<ErrorPage type="4041" title="Error 404, Not Found!" text={`Uh-oh! Looks like This Page doesn't Exist.`} />}
                  />
                }
              </Routes>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default App;
