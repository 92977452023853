import React, { useEffect, useState } from 'react';
import Section1 from '../../components/Sections/Section1';
import { MainDataService } from '../../services/mainData';
export default function Landing1() {
  const [formData, setFormData] = useState({
    id: null,
    google: null,
    apple: null,
  });

  const [socail, setSocail] = useState([]);

  let getAboutData = async () => {
    let response = await MainDataService.getData();
    setFormData({
      id: response.data.about._id,
      google: response.data.about.google,
      apple: response.data.about.apple,
    });
    setSocail(response.data.about.social);
  };

  useEffect(() => {
    getAboutData();
  }, []);

  return (
    <>
      <div className="overflow-hidden">
        <section id="about-us">
          <Section1 data={formData} />
        </section>
      </div>
    </>
  );
}
